<template>
    <div :class="size">
        <v-card>
            <template v-if="size === 'sm'">
                <v-card-title>
                    Google Calendar
                </v-card-title>
                <v-card-text class="py-0">
                    <v-timeline align-top dense>
                        <v-timeline-item color="pink" small>
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>5pm</strong>
                                </v-col>
                                <v-col>
                                    <strong>New Icon</strong>
                                    <div class="caption">
                                        Mobile App
                                    </div>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                        <v-timeline-item color="teal lighten-3" small>
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>3-4pm</strong>
                                </v-col>
                                <v-col>
                                    <strong>Design Stand Up</strong>
                                    <div class="caption mb-2">
                                        Hangouts
                                    </div>
                                    <v-avatar>
                                        <v-img
                                        src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                                        ></v-img>
                                    </v-avatar>
                                    <v-avatar>
                                        <v-img
                                        src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                                        ></v-img>
                                    </v-avatar>
                                    <v-avatar>
                                        <v-img
                                        src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                                        ></v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                        <v-timeline-item
                        color="pink"
                        small
                        >
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>12pm</strong>
                                </v-col>
                                <v-col>
                                    <strong>Lunch break</strong>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                        <v-timeline-item
                        color="teal lighten-3"
                        small
                        >
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>9-11am</strong>
                                </v-col>
                                <v-col>
                                    <strong>Finish Home Screen</strong>
                                <div class="caption">
                                    Web App
                                </div>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>
            </template>
            <template v-else>
                <v-card-title>
                    Google Calendar
                    <div class="text--secondary py-0 px-5">{{ dateTitle }}</div>
                </v-card-title>
                <v-sheet style="overflow:auto">
                    <v-calendar ref="calendar" interval-count="15" first-time="6" v-model="value" color="primary" type="week" :events="events" :weekdays="[1, 2, 3, 4, 5]" @change="updateRange" :interval-format="intervalFormat">
                            <template v-slot:event="{  eventSummary,  }">
                                <div class="v-event-draggable" v-html="eventSummary()" ></div>
                            </template>
                    </v-calendar>
                </v-sheet>
                <v-card-actions class="d-flex justify-center">
                    <v-btn class="mx-2 my-0" @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>Précédent
                    </v-btn>
                    <v-btn class="mx-2 my-0" @click="$refs.calendar.next()">
                        Suivant<v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'GoogleAgenda',
    props : {
        size : {
            type : String,
            require : true,
        },
    },
    data () {
        return {
            value: '',
            dateTitle : '',
            events: [],
            colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575']
        }
    },
    methods : {
        updateRange({start}) {

            let yr = start.year;
            let mn = start.month;

            const mNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            this.dateTitle = mNames[mn-1] + ' ' + yr;
        },
        intervalFormat(interval) {
            return interval.time
        }
    },
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 5;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
    }
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>